<template>
    <div id="gr-right-content-bottom-b">
        <div
            v-if="
                activeServiceObj.WORKORDER != -1 &&
                rolePrivilegesList.indexOf('PORTAL_ENTRANCE_WORKORDER') !== -1
            "
            @click="enterModule(activeServiceObj.WORKORDER)"
            style="color: #7367f0; background: #efedff"
            class="gr-left-cbb-c gr-left-cbb-c-w"
        >
            <span class="icon">
                <i class="iconfont guoran-askservice-logo03"></i>
            </span>
            <span class="a-text">AskService工单系统</span>
        </div>
        <div
            v-if="
                activeServiceObj.MANAGEMENT != -1 &&
                rolePrivilegesList.indexOf('PORTAL_ENTRANCE') !== -1
            "
            @click="enterModule(activeServiceObj.MANAGEMENT)"
            style="color: rgb(255, 196, 54); background: rgb(255, 248, 230)"
            class="gr-left-cbb-c gr-left-cbb-c-m"
        >
            <span class="icon">
                <i class="iconfont guoran-tongyichicun-guanlixitong1"></i>
            </span>
            <span class="a-text">管理系统</span>
        </div>
        <div
            v-if="
                activeServiceObj.IM != -1 &&
                rolePrivilegesList.indexOf('PORTAL_ENTRANCE_IM') !== -1
            "
            @click="enterModule(activeServiceObj.IM)"
            style="color: #09ddd5; background: #e1faf9"
            class="gr-left-cbb-c gr-left-cbb-c-i"
        >
            <span class="icon">
                <i class="iconfont guoran-guanlixitong1"></i>
            </span>
            <span class="a-text">AskChat即时通讯IM</span>
        </div>
    </div>
</template>
<script>
export default {
    props: ["activeServiceObj", "rolePrivilegesList"],
    data() {
        return {};
    },
    methods: {
        enterModule(redirectUrl) {
            let tempwindow = window.open("_blank");
            tempwindow.location = redirectUrl;
        },
    },
};
</script>
<style lang="less" scoped>
#gr-right-content-bottom-b {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    .gr-left-cbb-c-m:hover {
        border: solid 1px rgb(255, 196, 54);
    }
    .gr-left-cbb-c-w:hover {
        border: solid 1px #7367f0;
    }
    .gr-left-cbb-c-i:hover {
        border: 1px solid #09ddd5;
    }

    .gr-left-cbb-c {
        flex: 1;
        min-width: 150px;
        text-align: center;
        margin: 0 14px;
        background: #eff3ff;
        border-radius: 6px;
        height: 105px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .icon {
            margin-top: 20px;
            i {
                font-size: 40px;
            }
        }
        .a-text {
            margin-top: 6px;
            font-size: 13px;
        }
    }
}
</style>
